import React, { useEffect, useState } from "react";
import { NativeBaseProvider, Box, Input, Flex, Text, Button } from "native-base";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {

  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [token, setToken] = useState();

  const sendMessage = () => {
    if (title && body && token) {

      var data = JSON.stringify({
        "data": {},
        "notification": {
          "body": body,
          "title": title
        },
        "to": token
      });

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'key=AAAA8WBIx_k:APA91bFQxwnTRP7uIqdrSD417ednURfQhKhf6TFskPMYCNgqz2CsAMhHTVmIIvKsSw2KNlwjcADEApwPa_ZEUPlM0pUK73JAym3VLiX4yfW_73UjlHiKfhbT7NCBrhP1L72g5Ia00Wf7'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          toast.success("Sent Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        })
        .catch(function (error) {
          console.log(error);
          toast.error("Server Error!", {
            position: toast.POSITION.BOTTOM_CENTER
          });
        });

    } else {

      toast.error("All fields are reaquired!", {
        position: toast.POSITION.BOTTOM_CENTER
      });

    }
  }
  return (
    <NativeBaseProvider>
      <Box w="70%" alignSelf="center" bgColor="yellow.50" shadow={5} mt="10" borderRadius="10" mb="10">
        <Flex p="10">
          <Input placeholder="Message title" w="90%" alignSelf="center" my="5" bgColor="white" onChangeText={text => { setTitle(text) }} />
          <Input placeholder="Message Body" w="90%" alignSelf="center" my="5" bgColor="white" onChangeText={text => { setBody(text) }} />
          <Input placeholder="FCM Token" w="90%" alignSelf="center" my="5" bgColor="white" onChangeText={text => { setToken(text) }} />
        </Flex>
        <Button onPress={sendMessage} >Send message</Button>
      </Box>
      <ToastContainer />
    </NativeBaseProvider>
  );
}
